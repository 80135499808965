import { orange } from 'src/styles/colors'
import styled from 'styled-components'

export const CheckRadius = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${orange[200]};
  border-radius: 50%;
  width: 64px;
  height: 64px;
  margin: 0 auto 24px auto;
`

export const LinkButton = styled.a`
  max-width: 100%;
`

export const Button = styled.button`
  max-width: 100%;
`
