import React, { useState } from 'react'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import { Modal } from 'src/components/Modal'
import useDomReady from 'src/hooks/window/useDomReady'

import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'
import HeroChequeEspecial from './sections/hero/_index'

import QRcodeModalSucesso from './assets/images/qrcode-modal-sucesso.svg'

const ChequeEspecialSucesso = () => {
  const domReady = useDomReady()
  const [ isOpenModal, setIsOpenModal ] = useState(false)

  const urlMobileSucesso = 'https://bancointer.go.link/chequeEspecial?adj_t=1b9zzb42&adj_adgroup=contadigital&adj_creative=onboarding_chequeespecial_sucesso&adj_fallback=https%3A%2F%2Finter.co%2Fpra-voce%2Femprestimos%2Fcheque-especial%2F%3Futm_source%3Dsite%26utm_medium%3Dsite%26utm_campaign%3Dsite_contadigital_onboarding_chequeespecial_sucesso&adj_redirect_macos=https%3A%2F%2Finter.co%2Fpra-voce%2Femprestimos%2Fcheque-especial%2F%3Futm_source%3Dsite%26utm_medium%3Dsite%26utm_campaign%3Dsite_contadigital_onboarding_chequeespecial_sucesso'

  const openModalSucesso = domReady && (
    <Modal isModalOpen={isOpenModal} setIsModalOpen={setIsOpenModal} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpenModal}
        setIsModal={setIsOpenModal}
        title='Acesse o nosso Super App'
        subtitle="<span class='text-left'>Você será redirecionado para o Super App para utilizar o Cheque Especial.</span>"
        instructions="<span class='text-left'>Abra a câmera do seu celular e aponte para o QR Code acima. É rápido e fácil!</span>"
        qrCode={QRcodeModalSucesso}
      />
    </Modal>
  )

  return (
    <Layout pageContext={pageContext}>
      {openModalSucesso}
      <HeroChequeEspecial
        title="<span className='d-block d-lg-inline d-xl-block'>Você já pode usar </span> o Cheque Especial"
        subTitle='O Cheque Especial já está disponível pra você, acesse o Super App para utilizar.'
        setIcon='check'
        url={urlMobileSucesso}
        setIsOpenModal={setIsOpenModal}
      />
    </Layout>
  )
}

export default ChequeEspecialSucesso
