import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'

import IcCheck from '@interco/icons/build-v4/orangeds/MD/check'
import IcAttention from '@interco/icons/build-v4/orangeds/MD/attention'
import IcCanceled from '@interco/icons/build-v4/orangeds/MD/canceled'

import { WIDTH_MD } from 'src/styles/breakpoints'

import SectionComponent from 'src/components/SectionComponent'
import { CheckRadius, LinkButton, Button } from './styles'

type DataProps = {
  title: string;
  subTitle: string;
  url: string;
  setIcon: string;
  setIsOpenModal?: Function;
  isPage?: string;
}

const HeroChequeEspecial = ({ title, subTitle, url, setIcon, setIsOpenModal, isPage }: DataProps) => {
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleClick = () => {
    window.location.href = url
  }

  return (
    <SectionComponent
      id='cheque-especial'
      SectionStyles='py-5 bg-yellow--extra-light align-items-center'
      minHeight={{ sm: '401px', md: '436px', lg: '475px', xl: '616px' }}
    >
      <div className='col-12 col-md-6 col-xl-5 text-center'>
        <CheckRadius>
          {setIcon === 'check' && <IcCheck height={32} width={32} color='#EA7100' />}
          {setIcon === 'alert' && <IcAttention height={32} width={32} color='#EA7100' />}
          {setIcon === 'close' && <IcCanceled height={32} width={32} color='#EA7100' />}
        </CheckRadius>
        <h1
          className='fs-24 lh-26 fs-lg-28 lh-lg-30 fs-xl-36 lh-xl-40 font-citrina text-grayscale--500 fw-600 mb-4'
          dangerouslySetInnerHTML={ { __html: title } }
        />
        <p
          className='fs-16 lh-20 fs-lg-18 lh-lg-22 text-grayscale--400 mb-3'
          dangerouslySetInnerHTML={ { __html: subTitle } }
        />
        {isPage !== 'seguranca' &&
          width <= WIDTH_MD
            ? (
              <LinkButton
                href={url}
                title={isPage === 'erro' ? 'Central de ajuda' : 'Acessar Super App' }
                className='btn btn--lg bg-primary--500 text-white mt-4 text-none cursor-pointer'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    element_action: 'click button',
                    element_name: isPage === 'erro' ? 'Central de ajuda' : 'Acessar Super App',
                    section_name: title,
                    redirect_url: url,
                  })
                }}
              >
                {isPage === 'erro' ? 'Central de ajuda' : 'Acessar Super App' }
              </LinkButton>
            )
          : (
              isPage !== 'seguranca' &&
                <Button
                  title={isPage === 'erro' ? 'Central de ajuda' : 'Acessar Super App' }
                  className='btn btn--lg bg-primary--500 text-white mt-4 text-none cursor-pointer'
                  onClick={() => {
                    isPage !== 'erro' ? setIsOpenModal && setIsOpenModal(true) : handleClick()
                    sendDatalayerEvent({
                      section: 'dobra_01',
                      element_action: 'click button',
                      element_name: isPage === 'erro' ? 'Central de ajuda' : 'Acessar Super App',
                      section_name: title,
                    })
                  }}
                >
                  {isPage === 'erro' ? 'Central de ajuda' : 'Acessar Super App' }
                </Button>
            )
          }
      </div>
      {width >= WIDTH_MD &&
        <div className='col-12 col-md-6 col-lg-5 text-center'>
          <ImageWebp
            pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/cheque-especial/image.webp'
            altDescription='Um rapaz sentado à mesa de frente ao seu notebook e olhando para o seu celular'
            arrayNumbers={[ 336, 336, 376, 457, 457 ]}
            arrayNumbersHeight={[ 311, 311, 349, 423, 423 ]}
          />
        </div>
      }
    </SectionComponent>
  )
}

export default HeroChequeEspecial
